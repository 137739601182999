import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const Bio = () => {
  return (
    <BioContainerFlex>
        <BioImage>
            <StaticImage
            src="../assets/images/bio-light.jpeg"
            width={300}
            alt="Bio"
            placeholder="tracedSVG"
            quality={40}
            />
        </BioImage>
        <BioButton>
          <h3>Nous avons tous une histoire unique à raconter et à partager
          <div><h1><button onClick={()=>{navigate("/bio")}}>Ma Biographie</button></h1></div></h3>
          </BioButton>
    </BioContainerFlex>
  )
}

export default Bio

const BioContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5rem ;
  color: rgba(169, 73, 69, 0.636);
  background-color: #404640;  
      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0rem ;
        margin-top: 5rem ;
        background-color: white;  
  `
const BioImage = styled.div`
  flex: 50%;
  text-align: center;
  margin-top: 5rem ;
  heigth: auto;
  display: block;
      @media screen and (max-width: 768px) {

      }
`
const BioButton = styled.div`
  flex: 50%;
  padding: 6rem;
  h3 {
    font-size: 1.2rem;
    color : #404640;
    color:white;
    padding-top: 2rem;
    padding-right: 2rem;
    text-align: center;
    }
  button {
    padding: 1rem;
    margin-top: 30px;
    border-style: none;
    font-size: 1rem;
    color : #404640;
    font-weight: bold;
    background: #ab9668; 
      &:hover {
        background-color: white; 
        transform: translateY(-2px);
        color: #404640;
              }
      }

      @media screen and (max-width: 768px) {
        padding: 1rem;
        h3 {
          color : #404640;
          text-align: center;
          font-size: 1.2rem;
          }
          button{
            margin-bottom: 2rem;
            &:hover {
              background-color: #404640; 
              color: white;
                    }
            }
          
`