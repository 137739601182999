import React from 'react'
import styled from 'styled-components'
import Video from '../assets/videos/artist.mp4'

const Hero = () => {
  return (
   <HeroContainer> 
    <HeroBg>
        <VideoBg src={Video} type="video/mp4" autoPlay loop muted playsInline />
    </HeroBg>
     <HeroContent>
        <HeroItems> 
          <HeroH1>  Christine Grillet  </HeroH1>
          <HeroP> Artiste en mouvement </HeroP>
        </HeroItems>
     </HeroContent>
   </HeroContainer>
  )
}

export default Hero

 {/* Un overlay sombre sur la video avec :before pour mettre en avant le texte  */}
const HeroContainer = styled.div `
  background: #0c0c0c;
  display: flex;
  justify-content: center ;
  align-items: center;
  height: 103vh;
  width: auto;
  padding: 0 1rem;
  position: relative;
  margin-top: -80px;
  color: white;
  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    linear-gradient(180deg,rgba(0, 0, 0, 0.2) 0%,transparent 100%
    );
        @media screen and (max-width: 768px) {
          height: 104vh;
        }
  }
`

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`

const HeroContent = styled.div`
  z-index: 3;
  height:  calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px)) / 2;
`

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: white;
  line-height: 1.1;
  font-weight: bold;
`

const HeroH1 = styled.h1`
font-family: BetterGrade;  
  font-size: 14rem; 
  font-weight: 400;
  margin-bottom: 3.5rem;
  margin-top: 3.5rem;
  padding: 0 1rem;
    @media screen and (max-width: 768px) {
      font-size: 7rem; 
      margin-bottom: 3.5rem;
`

const HeroP = styled.p`
  font-size: 3rem; 
  margin-bottom: 2.5rem;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 2rem; 
    font-weight: 900;
    margin-bottom: 0.5rem;
` 
