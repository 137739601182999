import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import GalleryLight from '../components/GalleryLight'
import BioLight from '../components/BioLight'
import EventLight from '../components/EventLight'
{/* */} 

const IndexPage = () => (
  <Layout>
    {/* seo */}  
    <Seo title="Home" />
    <Hero />
    <GalleryLight />
    <BioLight />
    <EventLight />
  </Layout>
)

export default IndexPage


